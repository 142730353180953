<ngx-ui-loader></ngx-ui-loader>
<mat-sidenav-container>
    <mat-sidenav #sideNav role="navigation">
        <a mat-button
           routerLink="installs"
           routerLinkActive="active"
           (click)="sideNav.close()">
            <fa-icon icon='map-marked-alt'></fa-icon>
            My Installs
        </a>
        <a *ngIf="enableManagePremises === true" mat-button
           routerLink="manage-premises"
           routerLinkActive="active"
           (click)="sideNav.close()">
            <fa-icon icon='map-marked-alt'></fa-icon>
            Manage Premises
        </a>
        <a mat-button
           routerLink='login'
           routerLinkActive='active'
           (click)="logOut(); sideNav.close()">
            <fa-icon icon='sign-out-alt'></fa-icon>
            Log Out
        </a>
    </mat-sidenav>
    <mat-sidenav-content fxLayout='column'>
        <mat-toolbar color='primary'>
            <fa-icon icon='bars' (click)="sideNav.toggle()"></fa-icon>
            <h4>{{ title }}</h4>
        </mat-toolbar>
        <main id="main-component" fxFlex>
            <router-outlet></router-outlet>
        </main>
        <aex-footer [environment]="env" footerIcon="./assets/img/aex-logo.png"></aex-footer>
    </mat-sidenav-content>
</mat-sidenav-container>