import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { TSMap } from 'typescript-map';
import { IEnvironment } from '../../environments/environment-types';
import { IOperatorResolution } from '@aex/ngx-toolbox';

export interface IFnoConfiguration {
	reschedule: boolean,
	cancel: boolean,
	onHold: boolean,
	includeISPRef: boolean,
	preCheck: boolean,
	showRICA: boolean,
	stockRequired: boolean,
	serviceAssets: boolean,
	includeWeekend: boolean,
	proximity: number,
	imageTypeList: string[],
	customReasonDropDown:boolean,
	displayReasonDropDown:boolean,
	typeRescheduledList: string[],
	typeTypeCancelledList: string[],
	typeTypeOnHoldList: string[],
	nidImageTypeList: string[],
	aerialImageTypeList: string[],
	imageRequirements: string,
	showPreOrder: boolean,
	showRelocation: boolean,
	showFault: boolean,
	showPremiumFault: boolean,
	showPremiumRepair: boolean,
	showNidInstalls: boolean,
	displayAddressOnSummary: boolean,
	showCustomSurvey: boolean,
	showSolarInstallPreCheck: boolean,
	fsanFootnote: boolean,
	disableFsanValidation: boolean,
	disableServiceAssetsUpdate: boolean,
	installStatuses: IProgressionStatuses,
	faultStatuses: IProgressionStatuses,
	repairStatuses: IProgressionStatuses,
	preOrderStatuses: IProgressionStatuses,
	relocationStatuses: IProgressionStatuses,
	premiumFaultStatuses: IProgressionStatuses,
	premiumRepairStatuses: IProgressionStatuses,
	nidInstallStatuses: IProgressionStatuses,
	displayLayer3: boolean,
	displayInstallDiscovery: boolean,
	customPhrases: ICustomPhrases,
	showWorkOrderHistories: boolean,
	showPremiumStatus: boolean,
	showWorkSummary: boolean,
	useAssetIntegrationService: boolean,
	encryptLocalStore: boolean,
	showPortNumber: boolean,
	allowMicrosoftLogin: boolean,
	hideScheduledTime: boolean,
	reassign: boolean,
	hideLastComment: boolean,
	hasGoogleAnalytics: boolean,
	googleAnalyticsKey: string,
	dateFormat?: string,
	hasGoogleAnalytics4: boolean,
	googleAnalytics4Key: string,
	enableManagePremises: boolean,
}

export interface ICustomPhrases {
	distanceMetric: string;
	fsan: string;
	installationComplete: string,
	powerSupplyRequiredGcPud: string,
	powerSupplyRequiredIsp: string,
	Repairs:string,
	Installs:string,
	Relocations:string,
	PreOrders:string,
	PremiumFaults:string,
	PremiumRepairs:string,
	WorkOrders:string,
	Repair:string,
	Install:string,
	Relocation:string,
	PreOrder:string,
	PremiumFault:string,
	PremiumRepair:string,
	WorkOrder:string,
	NidInstalls: string,
	NidInstall: string,
	Province: string,
	City: string,
	Area: string,
	Premise: string,
}

export interface IEnvFile {
	environments: {[environment: string]: Partial<IEnvironment>};
	operatorResolution: IOperatorResolution[];
}

export enum InstallType {
	ALL = 0,
	INSTALL = 1,
	FAULT = 512,
	REPAIR = 1024,
	PREORDER = 4096,
	RELOCATION = 16384,
	PREMIUM_SUPPORT_FAULT = 32769,
	PREMIUM_SUPPORT_REPAIR = 65537,
	NIDINSTALL = 13,
}

export enum ServiceLevel {
	BASIC, PREMIUM, CRITICAL,
}

export enum InstallStatusFilter {
	IN_PROGRESS = 'In Progress',
	OVERDUE = 'Overdue',
	AWAITING = 'Awaiting Config',
	PENDING = 'Pending',
}
export enum NidInstallStatusFilter {
	IN_PROGRESS = 'NID Order',
	OVERDUE = 'Civil Drop',
	AWAITING = 'Optical Drop',
	PENDING = 'OSP Install Blockage',
}

export interface IProgressionStatuses {
	cancel: Guid;
	begin: Guid;
	finish: Dictionary<Guid>;
}


export enum InstallStatusId {
	// Noinspection JSUnusedGlobalSymbols
	INSTALLATION_SCHEDULE_PENDING = '029715DE-80AE-469F-A344-E984588E76D1',
	INSTALLATION_IN_PROGRESS = '0B3F0EFA-574A-49BA-B7B9-7824F9CCFEE8',
	PENDING_ONT_DISCOVERY = 'A9118371-6218-4DB6-9F37-89C4D8267645',
}

export enum FaultStatusId {
	// Noinspection JSUnusedGlobalSymbols
	PENDING_RESOLUTION = '8011EAEB-33D0-4EDE-8975-A4A24F156B53',
	NEED_INFORMATION = '78B9E2BA-C3F6-49E4-A160-DA5618EC907F',
	TESTING = 'F081FD4A-09EC-4A7D-8447-0263B462FB48',
}

export enum RepairStatusId {
	// Noinspection JSUnusedGlobalSymbols
	SCHEDULE_PENDING = '3209001A-B29B-41B3-9F45-0E3FA11DCB51',
	REPAIR_IN_PROGRESS = '123A0F5C-6491-4819-A218-4548E8996B14',
	TESTING = 'B5927C9C-CA0C-41B6-817C-C9EFA024C8F3',
}

export enum PreOrderStatusId {
	// Noinspection JSUnusedGlobalSymbols
	INSTALLATION_SCHEDULE_PENDING = '84F4EBDD-693B-489E-859A-6BA3B715E6C4',
	INSTALLATION_IN_PROGRESS = 'C2D21109-C236-42D6-AB24-4D6FB4AF860A',
	INSTALLATION_COMPLETE = '98263F5E-7CC7-4511-AA3E-7802736E5821',
}

export enum RelocationStatusId {
	// Noinspection JSUnusedGlobalSymbols
	PENDING_ONT_DISCOVERY = '4979B009-9058-4AC4-ACBF-34F4F8752A03',
	PENDING_RELOCATION = 'DC13FC0F-8144-4B5D-B670-020DA17950CD',
	RELOCATION_IN_PROGRESS = 'D74C3B59-25B4-4DDD-9A77-C3F550EB7AA2',
}

export enum PremiumFaultStatusId {
	// Noinspection JSUnusedGlobalSymbols
	PENDING_RESOLUTION = '8C709EB3-7981-4550-9F36-89E1CD908B9C',
	NEED_INFORMATION = '0E955D42-8FAD-4062-AB59-C8775ED6735B',
	TESTING = '8D68AF63-458C-44E3-8ABF-F3D76866FC0F',
}

export enum PremiumRepairStatusId {
	// Noinspection JSUnusedGlobalSymbols
	SCHEDULE_PENDING = '49CF4A44-83C8-46DB-A621-1F5AB5134A9D',
	MAINTENANCE_IN_PROGRESS = 'A23C7CE0-6A9F-4291-8BC2-2E7493CA1E4B',
	TESTING = 'A2D24E7E-C712-47AC-A242-237E18AEF769',
}
export enum NidInstallStatusId {
	// Noinspection JSUnusedGlobalSymbols
	CIVIL_DROP = '90A0537A-A5B4-42FD-9015-5436B403E527',
	OPTICAL_DROP = '2AB2D71E-E8C6-49FF-8A95-63C2CC42F098',
	NID_INSTALLATION_COMPLETE = '54B82BCD-A002-40B9-898B-57B87E95B7C9',
}

export interface IDateRange {
	startDate?: Date;
	endDate?: Date;
}

export interface IInstallFilter extends IDateRange {
	installType?: InstallType;
	dateFilterType: DateFilterType;
}

export enum DateFilterType {
	ALL, CURRENT_WEEK, CUSTOM, TODAY,
}

interface IInstallBase {
	account_id: string;
	schedule_date: string;
	schedule_time: string;
	scheduled_date_time: Date;
	status_id: string | any;
}

export interface IInstall extends IInstallBase {
	address: string;
	completed_date: string;
	created_at: string;
	distance: number;
	first_name: string;
	id: string;
	last_comment: string;
	last_name: string;
	latitude: number;
	longitude: number;
	map_url: string;
	mobile_number: number;
	navigation_address: string;
	on_hold: boolean;
	reference: string;
	status: InstallStatusFilter;
	status_class: string;
	type_id: InstallType;
	updated_at: string;
	description: string;
	summary: string;
	modified_by_id: string;
	// Transient properties - not from API
	extraInfo: IExtraInstallInfo;

	// Transient properties - only from Work Orders
	created_by?: string;
	external_reference?: string;
	assigned_id?: Guid;
	assigned?: string;
	assigned_user_id: Guid;
	assigned_user?: string;
	type?: string;
	comments: string;
}

export interface IInstallUpdate extends IInstallBase {
	serial_number: string;
	comments: string;
	on_hold: boolean,
	summary: string,
	description: string;
	assigned_id?: Guid;
	assigned?: string;
	assigned_user_id: Guid;
	assigned_user?: string;
	modified_by_id: string;
}

export interface IExtraInstallInfo {
	serialNumber: string;
	ispReference: string;
	validSerialNumbers: string[];
	inValidSerialNumbers: string[];
	requiredImages: string[];
	customerId: string;
	providerName?: string;
	premiseId?: string;
	serviceLevel?: number;
}

export interface IFilteredInstalls {
	installs: IInstall[];
	aggregates: TSMap<InstallStatusFilter, number>;
}

export interface IStockRequirement {
	enabled: boolean;
	work_order_id?: string;
	attributes: IStock[];
}

export interface IStock {
	required?: boolean;
	description: string;
	type?: string;
	value?: string | number;
}

export interface ISerialNumber {
	value: string;
	serial_number: boolean;
	type?: string;
}

export interface IAccountContainer {
	full_service: Partial<IAccount>
}

export interface IAccount {
	accountId: string;
	customer: Partial<ICustomer>;
	premise: Partial<IPremise>;
	area: IArea;
	point: IPoint;
	service: Partial<IService>;
	provider: Partial<IProvider>;
}

interface IProvider {
	name: string
}

interface ICustomer {
	id: string;
	first_name: string;
	last_name: string;
	business_name: string;
	email: string;
	identity_number: string;
	mobile_number: string;
}

interface IPremise {
	name: string;
	full_address: string;
	mdu_number: string;
	mdu_name: string;
	street_number: string;
	street_name: string;
	intersection: string;
	suburb: string;
	city: string;
	postal_code: string;
	country_code: string;
	province: string;
	external_reference: string;
	asset_reference: string;
}

export interface IArea {
	id: Guid
	parent_id: Guid,
	type_id: number,
	name: string,
	city: string,
	province: string,
}

interface IPoint {
	lat: string | number;
	lon: string | number;
}

export interface IService {
	id: string;
	isp_reference: string;
	customer_id: string;
	pppoe_username: string;
	pppoe_password?: string;
	wifi_username: string;
	wifi_password?: string;
	premise_id: string;
	level: number;
	port_number: number;
}

export interface IAccountUpdate {
	isp_reference?: string;
	wifi_username?: string;
	wifi_password?: string;
	port_number: number;
}

export interface IProcessBarcodeResponse {
	list: ISerialNumber[]
}

export interface IWorkOrderHistory {
	comments: string;
	modified_by: string;
	modified_date: string;
	company_changed: boolean;
	new_company: string;
	installer_changed: boolean;
	new_installer: string;
	person_changed: boolean;
	new_person: string;
	status_changed: boolean;
	new_status: string;
}

export interface IAttributeResponse {
	attributes: IAttribute[]
}

interface IAttribute {
	description: string
}

export class ImageUpload implements IUploadedImage {
	public id: number;
	public guid: string;
	public size: number;
	public progress: number;
	public updated_at: string;
	public docId: string;
	public status: FileUploadStatus = FileUploadStatus.PENDING;

	constructor(
		public data: string | Blob,
		public type: string,
		public filename: string,
		public namespace?: string,
	) {
		this.guid = Guid.raw();
	}

	public getFormData(includeNamespace: boolean = true): FormData {
		const formData = new FormData();

		formData.append('file[filename]', this.filename);
		if (includeNamespace)
			formData.append('file[namespace]', this.namespace);
		formData.append('upload', this.data);

		return formData;
	}
}

export interface IUploadedImage {
	id: number;
	guid: string;
	filename: string;
	namespace?: string;
	type: string;
	updated_at: string;
}

export enum FileUploadStatus {
	PENDING = 'Pending',
	IN_PROGRESS = 'Uploading',
	UPLOADED = 'Upload Done',
	ERROR = 'Error',
}

export type FileUploadProgress = TSMap<string, Observable<number>>;

export function newFileUploadProgress(): FileUploadProgress {
	return new TSMap<string, Observable<number>>();
}

export interface IRicaResponse {
	identification_id: string;
	residence_id: string;
	verified_by_id_number: string;
	verified: boolean;
}

export interface IRicaStatus {
	hasIdDocument: boolean;
	hasPorDocument: boolean;
	verifiedById: string;
	isVerified: boolean;
	namespace: string;
}

export enum RicaDocument {
	ID = 'ID Document',
	POR = 'Proof of Residence',
}

export abstract class HasErrors {
	public abstract errors: string[];
}

export interface IHasErrors {
	errors: string[];
}

export interface IDiscovery {
	hub_facility: string,
	hub_number: string,
	hub_switch_blade: number,
	hub_switch_port: number,
	hub_switch_model: string,
	hub_switch_ip_address: string,
	hub_rx_levels: string,
	hub_tx_levels: string,
	gateway_tx_levels: string,
	gateway_rx_levels: string,
	ont_model: string,
	ont_ip_address: string,
}

export interface ICompany {
	assigned_id: Guid;
	assigned: string;
}
export interface ICompanyPerson {
	assigned_user_id: Guid;
	assigned_user: string;
}

export interface IKeyValuePair<TK, T> {
	key: TK;
	value: T;
}

export interface IDictionary<T> {
	[key: string]: T;
}

export class Dictionary<T> implements IDictionary<T> {
	[index: string]: T;

	constructor(dictionary?: { key: string; value: T; }[]) {
		if (!dictionary) return;

		for (const value of dictionary)
			this[value.key] = value.value;
	}
}

export interface IValidMedium {
	customer_id: Guid;
	has_valid_medium: boolean;
}

export interface IUpdatedClientPremise {
	client_premise: IClientPremise,
	predefined_premise: IPredefinedPremise,
}
export interface IClientPremise {
	id: number,
	full_address: string,
	nid: boolean,
	asset_reference: string,
	predefined_premise_id: number,
}

export interface ILayer3Config {
	pppoe_username: string;
	pppoe_password: string;
	wifi_username: string;
	wifi_password: string;
}

export interface IPredefinedPremise {
	id: number,
	guid: Guid,
	full_text: string,
	area_id: Guid,
}

export interface IProvince {
	id: number,
	name: string,
}

export interface ICity {
	id: number,
	name: string,
	province_id: number
}

export interface IDeviceConfig {
	pppoe_username: string,
	pppoe_password: string,
	wifi_username: string,
	wifi_password: string,
	beacon_type: BeaconType,
	area_id: Guid,
	predefined_premise_id: Guid
}

export enum BeaconType {
	Basic = 'Basic',
	Wpa2 = 'Wpa2',
}
